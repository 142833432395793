const defaultLang = {
  pt: {
    current: "portuguese",
    menu: [
      { text: "Portfólio", link: "/portfolio" },
      { text: "Github", link: "https://github.com/supersonicgabs" },
      {
        text: "Linkedin",
        link: "https://www.linkedin.com/in/gabriel-fonseca-coutinho/",
      },
      { text: "Medium", link: "https://supersonicgabs.medium.com/" },
      { text: "Experience", link: "/experience/" },
      { text: "Contato", link: "/contact/" },
    ],
    home: {
      title: "Sobre Mim",
      text: [
        "Olá, sou Gabriel Fonseca desenvolvedor front-end de Santos, São Paulo, morando em Londres.",
        "Apaixonado por javascript, descobrir coisas novas, soluções simples, café e anos 60.",
        "Programando desde os meus 15 anos, comecei pelo básico do front-end (table e jQuery como todos hahaha), me apaixonei e nunca mais parei.",
        "Aqui estão alguns exemplos do meu trabalho. Dúvidas, sugestões ou orçamentos? Por favor entre em contato abaixo.",
      ],
      btns: [
        { link: "/portfolio", text: "Conheça meu trabalho" },
        { link: "/contact", text: "Entre em contato" },
      ],
    },
    filter: "Filtro",
    blog: ["Published by Gabriel Fonseca."],
    tags: [
      "Gulp",
      "Vanilla JS/ES6",
      "React",
      "SASS",
      "Jekyll",
      "Gatsby",
      "Netlify",
      "HTML",
      "CSS",
      "jQuery",
    ],
    noResults: "Nenhum resultado encontrado.",
    prevPage: "Págin Anterior",
    nextPage: "Próxima Página",
    contact: {
      title: "Contato",
      name: "Nome",
      email: "E-mail",
      subject: "Assunto",
      company: "Empresa",
      message: "Mensagem",
      send: "Enviar",
    },
    experience: {
      title: "Experiência",
      subtitleEducation: "Formação Acadêmica",
      education: [
        "Formado no curso técnico de Web Designer pela Microcamp (2010 - 2012).",
        "Formado em Análise e Desenvolvimento de Sistemas na Fatec Baixada Santista - Rubens Lara. (2015 – Primeiro Semestre de 2019).",
        "Curso online: The Complete Sass & SCSS Course: From Beginner to Advanced.",
        "Curso online: Node.js by NodeBR.",
        "Certificação: Scrum Professional Certificate (SFPC)",
      ],
      subtitleProfessional: "Experiência Profissional",
      companies: [
        {
          title: "E2X (Dezembro 2020 – Presente) - Front End Developer",
          list: [
            "Nesta empresa, trabalhei em um app de jogo de tabuleiro, que utilizava tecnologias como: Typescript, React, GraphQL, Material UI, AWS services e também utilizava TDD com Jest e testes automatizados com Cypress. A empresa usa scrum como metodologia ágil e GitHub como repositório de projetos.",
          ],
        },
        {
          title:
            "QZela (Janeiro 2020 – Novembro 2020) - Full Stack Developer/Senior Front End Developer",
          list: [
            "Nesta empresa, o principal produto é um app de ocorrências de zeladoria urbana, portanto, além da manutenção, trabalho em novas funcionalidades e melhorias no sistema que gerencia o aplicativo.",
            "O projeto foi construído usando React e Typescript, e também usa GraphQL para buscar os dados da API. Eu desenvolvi um novo recurso para o usuário pesquisar ocorrências por meio de polígonos no mapa (usando react-google-maps).",
            "Também desenvolvi o site da nova empresa usando Gatsby JS e Netlify. Eles usaram o GitHub como repositório do projeto.",
          ],
        },
        {
          title: "AGE (Maio 2019 – Janeiro 2020) - Front-end Developer Senior",
          list: [
            "Esta empresa possuía um sistema legado (SOC) como maior produto (com 1.410 clientes) e a equipe de front-end foi responsável pelo início da construção da nova versão do sistema e também trazendo novas tecnologias para outros projetos.",
            "Desenvolvemos o início da nova versão do sistema legado (com todos os componentes em um guia de estilo, páginas principais).",
            "Implementei o Gulp como executor de tarefas automático e pacotes npm na nova versão do sistema legado e nas novas páginas do site da empresa.",
            "Desenvolvemos um site de busca com Google Maps API e React.",
            "Já trabalhei com SCRUM e kanban como metodologias ágeis, usamos o Figma para recortar os layouts e tivemos contato com várias equipes como UX / UI, publicidade e desenvolvedores back-end.",
            "Usamos TortoiseGit como repositório de projetos.",
          ],
        },
        {
          title: "WebNets (Junho 2011 – Maio 2019) - Front-End Developer",
          list: [
            "Nesta empresa desenvolvi vários projetos responsivos e acessíveis, como sites estáticos, e-commerce, landing pages e sites de prefeituras, alguns com o conceito mobile-first e alguns usando frameworks CSS como Bootstrap e Google Material Design.",
            "Trabalhei com a API Javascript do Google Maps, construí os recursos de acessibilidade que foram implementados em quase todos os sites e desenvolvi sites de algumas empresas menores, blogs e sites de prefeituras usando Gulp e Jekyll.",
            "Eu ajudei a implementar gulp na empresa e desenvolvedores de back-end para entender Vanilla JS e aprendi como gerenciar repositórios de projeto usando Tortoise SVN.",
            "Tive contato com alguns projetos que utilizavam jQuery e alguns projetos .NET utilizando MVC.",
          ],
        },
      ],
    },
    success: {
      title: "Sucesso!",
      text: "Sua mensagem foi enviada com sucesso! Entrarei em contato o mais rápido possível.",
      portfolio: "Visite meu Portfólio",
      again: "Enviar outra mensagem",
    },
  },
  en: {
    current: "english",
    menu: [
      { text: "Portfolio", link: "/portfolio" },
      { text: "Github", link: "https://github.com/supersonicgabs" },
      {
        text: "Linkedin",
        link: "https://www.linkedin.com/in/gabriel-fonseca-coutinho/",
      },
      { text: "Medium", link: "https://supersonicgabs.medium.com/" },
      { text: "Experience", link: "/experience/" },
      { text: "Contact", link: "/contact/" },
    ],
    home: {
      title: "About me",
      text: [
        "Hello, I'm Gabriel Fonseca front-end developer from Santos, São Paulo, living in London/UK.",
        "Passionate about javascript, discover new things, simple solutions, coffee and the 60s.",
        "Programming since I was 15 years old, I started with the front-end basics (table and jQuery). I fell in love and never stopped developing.",
        "Here are some examples of my work. Questions, suggestions or quotes? Please get in touch below.",
      ],
      btns: [
        { link: "/portfolio", text: "Know my work" },
        { link: "/contact", text: "Get in touch" },
      ],
    },
    filter: "Filter",
    blog: ["Published by Gabriel Fonseca."],
    tags: [
      "Gulp",
      "Vanilla JS/ES6",
      "React",
      "SASS",
      "Jekyll",
      "Gatsby",
      "Netlify",
      "HTML",
      "CSS",
      "jQuery",
    ],
    noResults: "No results found.",
    prevPage: "Previous Page",
    nextPage: "Next Page",
    contact: {
      title: "Get in Touch",
      name: "Name",
      email: "E-mail",
      subject: "Subject",
      company: "Company",
      message: "Message",
      send: "Send",
    },
    experience: {
      title: "Experience",
      subtitleEducation: "Educational background",
      education: [
        "Certificate in Web Designer at Microcamp (2010 - 2012).",
        "Graduate in Análise e Desenvolvimento de Sistemas (Analysis and Systems Development) in Fatec Baixada Santista - Rubens Lara. (2015 – First Semester of 2019).",
        "Online Course: The Complete Sass & SCSS Course: From Beginner to Advanced.",
        "Online Course: Node.js by NodeBR.",
        "Certification: Scrum Professional Certificate (SFPC)",
      ],
      subtitleProfessional: "Professional background",
      companies: [
        {
          title: "E2X (December 2020 – Present) - Front End Developer",
          list: [
            "In this company, I worked in a board game app, which used technologies such as: Typescript, React, GraphQL, Material UI, AWS services and also used TDD with Jest and automated testing with Cypress. The company use scrum as agile methodology and GitHub as project repository.",
          ],
        },
        {
          title:
            "QZela (January 2020 – November 2020) - Full Stack Developer/Senior Front End Developer",
          //  text: 'In this company, the principal product is and app of urban janitorial occurrences, so besides the maintenance,  I work in new features and improvements.',
          list: [
            "In this company, the principal product is an app of urban janitorial occurrences, so besides the maintenance, I work in new features and improvements of the system that manages the application.",
            "The project was built using React and Typescript, and also uses GraphQL to fetch the API data. I’ve developed a new feature for the user to search for occurrences through polygons on the map (using react-google-maps).",
            "I also developed the new company’s website using Gatsby JS and Netlify. They used GitHub as a project repository.",
          ],
        },
        {
          title: "AGE (May 2019 – January 2020) - Front-end Developer Senior",
          //  text: 'This company had a legacy system (SOC) as the biggest product and the front-end team was responsible for the beginning of the construction of the new version of the system and also bring in new technologies to other projects.',
          list: [
            "This company had a legacy system (SOC) as the biggest product (with 1.410 clients) and the front-end team was responsible for the beginning of the construction of the new system version and also bringing in new technologies to other projects.",
            "We developed the beginning of the new version of the legacy system (with all the components in a styleguide, key pages).",
            "I’ve implemented Gulp as automatic task runner and npm packages in the new version of the legacy system and on the new pages of the company’s website.",
            "We developed a search website with Google Maps API and React.",
            "I’ve worked with SCRUM and kanban as agile methodologies, we used Figma to crop the layouts and had contact with a lot of teams such as UX/UI, publicity and back-end developers.",
            "We used TortoiseGit as Project Repository.",
          ],
        },
        {
          title: "WebNets (June 2011 – May 2019) - Front-End Developer",
          //  text: 'In this company I’ve developed a lot of responsive and accessible projects, such as static websites, e-commerces, landing pages and prefectures websites.',
          list: [
            "In this company I developed a lot of responsive and accessible projects, such as static websites, e-commerce, landing pages and prefectures websites, some with the mobile-first concept and some using CSS frameworks like Bootstrap and Google Material Design.",
            "I Worked with the Javascript Google Maps API, built the accessibility features that have been implemented on almost every site and developed some smaller companies websites, blogs and prefectures websites using Gulp and Jekyll",
            "I helped to implement gulp in the company and backend developers to understand Vanilla JS and learned how to manage project repositories using Tortoise SVN",
            "Had contact with some projects that used jQuery and some .NET projects using MVC.",
          ],
        },
      ],
    },
    success: {
      title: "Success!",
      text: "Your message has been sent successfully! I will contact you as soon as possible.",
      portfolio: "Know my work",
      again: "Send another message",
    },
    footer: {
      terms: "Terms of use",
      privacy: "Privacy policy",
    },
  },
}

export default defaultLang
